<template>
  <WpSelect
    id="lstPrinters"
    v-model="selectedPrinter"
    select-class="rounded-lg absolute bottom-2 right-8 !w-72"
    name="lstPrinters"
    :options="clientPrinters"
    :selected-value="selectedPrinter"
  />
</template>

<script setup>
import '~/node_modules/jsprintmanager/JSPrintManager.js'
const { initPrintManager, selectedPrinter, clientPrinters } = usePrintManager()

onMounted(() => {
  initPrintManager()
})
</script>
