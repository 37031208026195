export default function usePrintingErrors() {
  const { errors } = storeToRefs(usePrintingStore())

  const authorizationErrors = {
    noMedForATK: 'noMedForATK',
    latestPrescriptionOpen: 'latestPrescriptionOpen',
    authorizationNeeded: 'authorizationNeeded',
    prescriptionNeeded: 'prescriptionNeeded'
  }

  function pushError(code: string, message: string) {
    if (!errors.value.length) errors.value = [{ code, message }]
    if (!errors.value.find((error) => error.code === code)) {
      errors.value.push({ code, message })
    }
  }

  function removeError(errorCode: string) {
    if (!errors.value.length) return
    const errorIndex = errors.value.findIndex(
      (error) => error.code === errorCode
    )
    if (errorIndex !== -1) errors.value.splice(errorIndex, 1)
  }

  return {
    authorizationErrors,
    pushError,
    removeError
  }
}
