<template>
  <WpAlert variant="info" class="mb-4">
    {{ message }}
    <template #button>
      <div class="text-right align-middle">
        <WpButton variant="signal-blue" @click="selectParcelModal = true">
          {{ $t('printing.selectDeliveryParcel') }}
        </WpButton>
      </div>
    </template>
  </WpAlert>

  <PrintingSelectParcelModal />
</template>

<script lang="ts" setup>
const { selectParcelModal } = storeToRefs(usePrintingStore())

// eslint-disable-next-line
const { message } = defineProps<{
  message: string
}>()
</script>
