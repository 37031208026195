<template>
  <div class="flex">
    <div
      v-for="shipmentType in options"
      id="ShipmentTypes[option].id"
      :key="ShipmentTypes[shipmentType].id"
      class="mr-4 flex w-full flex-1 cursor-pointer justify-between rounded-lg border-2 border-transparent bg-zinc-100 p-4 hover:border-signal-blue-500"
      :class="customClass(shipmentType)"
    >
      <div class="w-full" @click="onMethodSelected(shipmentType)">
        <div class="flex justify-between">
          <h5 class="font-bold">{{ ShipmentTypes[shipmentType].label }}</h5>
        </div>
        <p class="text-sm text-signal-blue-500">
          {{ ShipmentTypes[shipmentType].description }}
        </p>
      </div>
      <CancelShipmentMethod
        v-if="selectedShipmentMethod === shipmentType"
        class="z-999 text-stone-300"
        :option="shipmentType"
        :disabled="isDisabled(shipmentType)"
      />
      <StarIconBorder
        v-else-if="preferredShipmentMethods.includes(shipmentType)"
        class="z-999"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ShipmentTypeEnum } from '~/models'

const {
  errors,
  labelChangeModalOpen,
  loadingPrintingLabel,
  loadingZohoValidation,
  multipleDisputedParcels,
  preferredShipmentMethods,
  selectedParcel,
  selectedShipmentMethod,
  userDetails,
  isActivePickupPoint
} = storeToRefs(usePrintingStore())

const { handleShipmentTypeClicked } = usePrinting()
const { ShipmentTypes } = useShipment()

const { options } = defineProps<{
  options: any[]
}>()

function isDisabled(shipmentType: ShipmentTypeEnum): boolean {
  if (shouldDisablePickupMethod(shipmentType)) {
    return true
  }

  if (errors.value.length) {
    return true
  }

  if (loadingPrintingLabel.value || loadingZohoValidation.value) {
    return true
  }

  if (restrictToHomeDeliveryOnly(shipmentType)) {
    return true
  }

  return false
}

function restrictToHomeDeliveryOnly(shipmentType: ShipmentTypeEnum): boolean {
  if (!multipleDisputedParcels.value) {
    return false
  }

  return ['postNLDefault', 'postNLMailbox', 'trunkrsMailbox'].includes(
    shipmentType
  )
}

function shouldDisablePickupMethod(shipmentType: ShipmentTypeEnum) {
  const pickupPoint = userDetails.value?.PickupPoint
  const deliveryPreference = userDetails.value?.DeliveryPreference

  const disablePostnlPickup =
    shipmentType === 'postNLPickup' && pickupPoint?.Carrier === 'budbee'
  const disableBudbeePickup =
    shipmentType === 'budbeeBox' && pickupPoint?.Carrier === 'postnl'

  if (shipmentType === 'postNLPickup' || shipmentType === 'budbeeBox') {
    if (deliveryPreference != 'pickupPoint') {
      return true
    }

    if (!isActivePickupPoint.value) {
      return true
    }
    if (disableBudbeePickup || disablePostnlPickup) {
      return true
    }
  }

  return false
}

function onMethodSelected(shipmentType: ShipmentTypeEnum) {
  if (isDisabled(shipmentType)) return

  selectedShipmentMethod.value = shipmentType
  if (
    selectedParcel.value?.ShipmentMethod &&
    shipmentType !== selectedParcel.value.ShipmentMethod
  ) {
    labelChangeModalOpen.value = !labelChangeModalOpen.value
  } else {
    handleShipmentTypeClicked(shipmentType)
  }
}

function customClass(shipmentType: ShipmentTypeEnum) {
  if (isDisabled(shipmentType)) {
    return '!cursor-not-allowed opacity-50 hover:!border-transparant'
  }

  if (
    selectedParcel?.value?.ShipmentReference &&
    selectedShipmentMethod.value === shipmentType
  ) {
    return '!border-signal-blue-500'
  }
}
</script>
