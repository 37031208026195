<template>
  <h1 class="mb-4 text-3xl">{{ $t('header.labelPrinting') }}</h1>
  <WpInput
    v-model.trim="searchQuery"
    name="searchBundleId"
    :placeholder="t('printing.searchPlaceholder')"
    autofocus
    input-class="!rounded-full pl-14"
  >
    <template #prepend>
      <BarcodeIcon class="absolute left-4 top-[9px]" />
    </template>
  </WpInput>

  <PrintingError
    v-if="noParcelNotice"
    :message="t('printing.noDeliveryFoundForBarcode')"
    :error-code="'noParcel'"
    class="mt-4"
  />

  <WpAlert
    v-if="showPreviouslyPrintedAlert || showPreviouslyPrintedMultipleTimesAlert"
    class="my-4"
  >
    <p
      v-if="showPreviouslyPrintedMultipleTimesAlert"
      v-sane-html="$t('printing.labelIsPrintedAgain')"
    />
    <p v-else>
      {{ $t('printing.labelIsPrinted') }}
    </p>

    <template #button>
      <WpButton
        v-if="!showPrintCard"
        variant="signal-blue-outline"
        size="md-wide"
        @click="showPrintCard = true"
      >
        {{ $t('printing.showLastPrinted') }}
      </WpButton>
    </template>
  </WpAlert>

  <div
    v-if="parcels.length && userDetails"
    class="relative z-10 mt-4 overflow-y-scroll rounded-xl"
  >
    <WpCard v-if="showPrintCard" padding="lg">
      <h3 class="text-2xl">{{ t('printing.chooseShipmentMethod') }}</h3>
      <div class="my-4 flex">
        <PrintingUserDetails :user="userDetails" />
        <div class="max-w-[350px]">
          <template v-if="addresses.length">
            <PrintingAddress
              v-for="(address, addressIndex) in addresses"
              :key="addressIndex"
              :address-index="addressIndex"
              :address="address"
              :is-enabled="
                (addressIndex === 0 &&
                  ['homeAddress', 'pickupPoint'].includes(
                    userDetails.DeliveryPreference ?? ''
                  )) ||
                (addressIndex === 1 &&
                  userDetails.DeliveryPreference === 'deliveryAddress') ||
                (!userDetails.DeliveryPreference &&
                  addressIndex === addresses.length - 1)
              "
            />
          </template>
          <p v-else class="text-italic mb-4 text-red-500">
            {{ $t('printing.noAddressFound') }}
          </p>
          <div class="mb-4 grid grid-cols-[200px_250px] gap-4">
            <p class="font-bold">
              {{ $t('printing.deliveryPreference') }}
            </p>
            <p>{{ getDeliveryPreference(userDetails.DeliveryPreference) }}</p>
          </div>
          <div class="mb-4 grid grid-cols-[200px_250px] gap-4">
            <p class="font-bold">
              {{ $t('printing.delivery') }}
            </p>
            <PrintingParcelDetails v-if="selectedParcel" />
          </div>
        </div>
      </div>

      <WpAlert v-if="multipleDisputedParcels" variant="danger" class="mb-4">
        {{ t('parcel.multipleDisputedParcels') }}
      </WpAlert>

      <template v-for="(error, index) in errors" :key="`error-${index}`">
        <PrintingSelectParcel
          v-if="error.code === 'multipleParcels'"
          :message="error.message"
        />
        <template v-else>
          <PrintingError
            v-if="selectedParcel"
            :message="error.message"
            :error-code="error.code"
            class="mb-4"
          />
        </template>
      </template>
      <LoadingIndicator
        v-show="
          loadingShipment || loadingPrintingLabel || loadingZohoValidation
        "
      />

      <PrintingSelectDeliveryMethod
        v-show="
          !loadingShipment &&
          !errors.find((err) => err.code === 'multipleParcels')
        "
      />
    </WpCard>
  </div>

  <LoadingIndicator v-if="loadingParcels" class="mt-6" />

  <PrintingPrinterSelect />
</template>

<script lang="ts" setup>
import type { DeliveryPreference, Parcel } from '~/models'

definePageMeta({
  middleware: 'auth'
})

const { $resetPrintingStore } = usePrintingStore()
const {
  addresses,
  errors,
  loadingPrintingLabel,
  loadingShipment,
  loadingZohoValidation,
  multipleDisputedParcels,
  parcels,
  searchQuery,
  selectedParcel,
  selectedShipmentMethod,
  showPreviouslyPrintedAlert,
  showPreviouslyPrintedMultipleTimesAlert,
  showPrintCard,
  userDetails
} = storeToRefs(usePrintingStore())

const { fetchParcelsByAPROId, handleSearchAPROorBarcode } = useParcels()
const { fetchParcelsBySearchFieldAndQuery, handleParcelDocs } = usePrinting()
const { focusAndSelectInput } = useHelpers()
const { t } = useI18n()

watchDebounced(
  searchQuery,
  async (searchNew, searchOld) => {
    if (searchNew === searchOld) return

    // Reset all values used for printing
    $resetPrintingStore()

    // Prevent searching below 6 characters (APRO, is 6 characters long)
    if (!searchNew || searchNew.length < 6) return
    loadingShipment.value = true
    await fetchParcels(searchQuery.value)
    focusAndSelectInput('searchBundleId')
  },
  { debounce: 500 }
)

watch(
  () => selectedParcel.value,
  (parcel) => {
    if (parcel?.ShipmentReference) {
      showPrintCard.value = false
      showPreviouslyPrintedAlert.value = true
      selectedShipmentMethod.value = parcel.ShipmentMethod
    }
  }
)

const loading = ref(false)
async function fetchParcels(query: string) {
  loading.value = true

  const { searchField, queryString } = handleSearchAPROorBarcode(query)
  if (searchField === 'APROId') {
    const parcels = await fetchParcelsByAPROId(queryString)

    await handleParcelDocs(parcels as Parcel[])
  } else {
    await fetchParcelsBySearchFieldAndQuery(queryString, searchField)
  }

  loading.value = false
  loadingShipment.value = false
}

function getDeliveryPreference(deliveryPreference?: DeliveryPreference) {
  if (!deliveryPreference) {
    const userAddresses = userDetails.value?.ProfileDataMap?.Addresses

    let deliveryType = 'homeAddress'
    if (userAddresses && userAddresses?.length > 1) {
      deliveryType = 'deliveryAddress'
    }

    // If no preference was set, only check for home or delivery address deliveries
    return t(`printing.preferences.${deliveryType}`)
  }

  return t(`printing.preferences.${deliveryPreference}`)
}

const loadingParcels = computed(
  () =>
    (!parcels.value.length || !userDetails.value) &&
    searchQuery.value.length > 7 &&
    !noParcelNotice.value
)

const noParcelNotice = computed(
  (): boolean =>
    errors.value.filter((err) => err.code === 'noParcel' && !loading.value)
      .length > 0
)
</script>
