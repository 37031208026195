<template>
  <div class="grid auto-rows-max grid-cols-2">
    <PrintingUserDetailItem
      :label="t('printing.name')"
      :is-valid="isValidFullname"
    >
      <a
        :href="`https://crm.zoho.eu/crm/org20071953791/search?searchword=${user.id}&sModules=Contacts&userOption=false`"
        target="_blank"
        class="group block w-full underline hover:text-aubergine-500"
      >
        <span class="group-hover:hidden">
          {{ user?.ProfileDataMap?.FirstName }}
          {{ user?.ProfileDataMap?.LastName }}
        </span>
        <span class="hidden group-hover:block">
          {{ $t('appUser.openZohoCRM') }}
        </span>
      </a>
    </PrintingUserDetailItem>

    <PrintingUserDetailItem
      :label="t('printing.birthDate')"
      :is-valid="!!user?.ProfileDataMap?.BirthDate"
    >
      <p>{{ formatTimestamp(user?.ProfileDataMap?.BirthDate!) }}</p>
    </PrintingUserDetailItem>

    <PrintingUserDetailItem :label="t('printing.APRO')" :is-valid="isValidApro">
      <a
        v-if="isValidApro"
        :href="aproLink(user?.ExternalUserData?.AproData?.PatientId)"
        target="_blank"
        class="group block w-full underline hover:text-aubergine-500"
      >
        <span class="group-hover:hidden">
          {{ user?.ExternalUserData?.AproData?.PatientId }}
        </span>
        <span class="hidden group-hover:block">
          {{ $t('appUser.openApro') }}
        </span>
      </a>
      <p v-else>{{ t('appUser.noAPROnumberFound') }}</p>

      <a :href="`/?search=${user.Email}`" target="_blank" class="mr-4">
        <MagnifyIcon />
      </a>
    </PrintingUserDetailItem>

    <PrintingUserDetailItem
      :label="t('printing.status')"
      :is-valid="isValidUser"
    >
      <p>{{ customerStatusMessage }}</p>
    </PrintingUserDetailItem>

    <PrintingUserDetailItem
      :label="t('printing.welcomeGift')"
      :is-valid="isValidWelcomeGift"
    >
      {{ user?.ConfirmedWelcomeGift }}
    </PrintingUserDetailItem>
  </div>
</template>

<script lang="ts" setup>
import type { AppUser } from '~/models'

const { errors } = storeToRefs(usePrintingStore())
const { t } = useI18n()
const { formatTimestamp } = useHelpers()
const { aproLink } = useAppUsers()

const { user } = defineProps<{
  user: AppUser
}>()

const isValidFullname = computed(
  (): boolean =>
    !!(user?.ProfileDataMap?.FirstName && user?.ProfileDataMap?.LastName)
)

const isValidApro = computed(
  (): boolean => !!user?.ExternalUserData?.AproData?.PatientId
)

const customerStatusMessage = computed((): string => {
  if (!user?.CustomerStatus) return ''

  if (user?.CustomerStatus?.IsActive) return t('printing.active')

  return user?.CustomerStatus?.IsCancelled
    ? t('printing.unsubscribed')
    : t('printing.userIsNotAClient')
})

// Error codes are coming from Zoho
// TODO: Is this still needed, as it's not coming from Zoho anymore
const isValidUser = computed(
  (): boolean =>
    !errors.value.find(
      (error) =>
        error.code === 'dealNotFound' || error.code === 'activeDealNotFound'
    ) && !user?.CustomerStatus?.IsCancelled
)

// Error code is coming from Zoho
const isValidWelcomeGift = computed(
  (): boolean => !errors.value.find((error) => error.code === 'giftNotSent')
)
</script>
