<template>
  <WpModal v-model:open="selectParcelModal" no-background>
    <template v-for="parcel in parcels" :key="parcel.id">
      <ParcelListItem :parcel="parcel" class="mb-4" hide-action-buttons />
      <div
        v-if="parcels.length > 1"
        class="-mt-8 mb-8 rounded-b-xl bg-white p-4"
      >
        <WpAlert
          :variant="isSelectedParcel(parcel.id) ? 'success' : 'info'"
          text-class="text-sm"
        >
          {{
            isSelectedParcel(parcel.id)
              ? $t('printing.selectedForDelivery')
              : $t('printing.noticeMultipleParcels')
          }}

          <template #button>
            <WpButton
              :variant="
                isSelectedParcel(parcel.id)
                  ? 'signal-green-dark'
                  : 'signal-blue'
              "
              size="md-wide"
              @click="parcelSelected(parcel)"
            >
              {{
                isSelectedParcel(parcel.id)
                  ? $t('printing.selected')
                  : $t('printing.selectParcelForDelivery')
              }}
            </WpButton>
          </template>
        </WpAlert>
      </div>
    </template>
  </WpModal>
</template>

<script lang="ts" setup>
import type { Parcel } from '~/models'

const { parcels, selectedParcel, selectParcelModal } =
  storeToRefs(usePrintingStore())
const { resetAuthorizationErrors, parcelValidationCheck } =
  usePrintingValidation()

const { removeError } = usePrintingErrors()

async function parcelSelected(parcel: Parcel) {
  selectedParcel.value = parcel
  selectParcelModal.value = false
  removeError('multipleParcels')
  resetAuthorizationErrors()
  await parcelValidationCheck()
}

function isSelectedParcel(parcelId: string) {
  return selectedParcel.value?.id === parcelId
}
</script>
