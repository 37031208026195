export default function useShipmentData() {
  const ampShipmentTypes = ['ampCooled', 'ampCooledUncooled']

  const runnerShipmentTypes = ['spoedkoerierDefault', 'spoedkoerierColleague']

  const sendcloudShipmentTypes = [
    'budbeeBox',
    'postNLDefault',
    'postNLMailbox',
    'postNLHome',
    'postNLPickup',
    'trunkrsDefault',
    'trunkrsMailbox'
  ]

  return {
    ampShipmentTypes,
    runnerShipmentTypes,
    sendcloudShipmentTypes
  }
}
