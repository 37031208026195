<template>
  <WpAlert
    :variant="
      props.errorCode === 'multipleDisputedParcels' ? 'danger' : 'error'
    "
  >
    <p>
      <span v-sane-html="message" />
    </p>
    <template #button>
      <div class="text-right align-middle">
        <WpButton
          v-if="!['multipleParcels', 'noParcel'].includes(errorCode)"
          variant="signal-blue"
          @click="confirmAction()"
        >
          {{ confirmActionLabel }}
        </WpButton>
        <WpButton
          v-if="errorCode !== 'noParcel'"
          variant="signal-blue-outline"
          class="ml-4"
          @click="cancelAction()"
        >
          {{ cancelActionLabel }}
        </WpButton>
      </div>
    </template>
  </WpAlert>
</template>

<script lang="ts" setup>
import { Timestamp } from 'firebase/firestore'

const { t } = useI18n()
const { removeError } = usePrintingErrors()
const { sendEventNotificare } = useHelpers()
const { userDetails } = usePrintManager()
const { updateAppUser } = useAppUsers()
const { zohoUpdateHoldShipment } = useZoho()

const props = defineProps<{
  message: string
  errorCode: string
}>()

const confirmActionLabel = computed(() => {
  if (props.errorCode === 'giftNotSent') return t('printing.giftAdded')
  if (
    props.errorCode === 'holdShipment' ||
    props.errorCode === 'authorizationNeeded' ||
    props.errorCode === 'noMedForATK' ||
    props.errorCode === 'prescriptionNeeded'
  ) {
    return t('printing.sendAnyway')
  }
  return 'OK'
})

// Potential Zoho error codes:
// holdShipment;            Shipment should not be sent. Should have a 'holdShipmentReason'
// latestPrescriptionOpen;  Latest prescription for contact has an open status

async function confirmAction() {
  const errorCode = props.errorCode
  removeError(errorCode)

  if (!userDetails.value) return

  if (errorCode === 'giftNotSent') {
    return await updateAppUser(userDetails.value.id, {
      ConfirmedWelcomeGiftSentAt: Timestamp.fromDate(new Date())
    })
  }

  if (errorCode === 'holdShipment') {
    return await zohoUpdateHoldShipment(userDetails.value.id)
  }
}

const cancelActionLabel = computed(() => {
  return props.errorCode === 'giftNotSent'
    ? t('printing.noStock')
    : t('printing.cancel')
})

async function cancelAction() {
  if (props.errorCode === 'giftNotSent') {
    if (!userDetails.value) return

    sendEventNotificare({
      guid: userDetails.value.id,
      eventName: 'welcome_gift_out_of_stock',
      eventData: {
        selected_welcome_gift: userDetails.value.ConfirmedWelcomeGift ?? ''
      }
    })
  }
  removeError(props.errorCode)
}
</script>
