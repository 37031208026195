export default function useZoho() {
  const { loadingZohoValidation } = storeToRefs(usePrintingStore())
  const config = useRuntimeConfig()
  const { pushError } = usePrintingErrors()
  const { apiPost } = useApi()
  const { reportError } = useSentry()

  async function zohoPreshipmentValidation(userGuid: string) {
    await callZohoFunction(userGuid, 'preShipmentValidation')
  }

  async function zohoUpdateHoldShipment(userGuid: string) {
    await callZohoFunction(userGuid, 'updateHoldShipment')
  }

  async function callZohoFunction(
    userGuid: string,
    functionName: 'preShipmentValidation' | 'updateHoldShipment'
  ) {
    if (config.public.FB_PROJECT_ID == 'medapp-apotheek-dev') {
      return
    }
    loadingZohoValidation.value = true
    try {
      const [response] = await apiPost<any[]>('zoho/call-api', {
        body: {
          guid: userGuid,
          zohoFunction: functionName
        }
      })

      handleZohoFunctionResponse(response)
    } catch (error) {
      reportError('Error searching for given GUID', {
        error,
        userGuid,
        functionName
      })
    }
    loadingZohoValidation.value = false
  }

  const handleZohoFunctionResponse = (response: any) => {
    // If the call was successful, check the Zoho output of the function
    if (response.code === 'success') {
      const { details } = response
      const output = JSON.parse(details.output)

      if (output.code === 'holdShipment' && output.holdShipmentReason) {
        output.error += `: ${output.holdShipmentReason}`
      }

      if (output.status === 'failed') {
        pushError(output.code, output.error)
      }

      return output
    }
  }

  return {
    zohoPreshipmentValidation,
    zohoUpdateHoldShipment
  }
}
