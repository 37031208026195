<template>
  <p
    v-if="selectedParcel"
    class="cursor-pointer underline hover:text-aubergine-500"
    @click="selectParcelModal = true"
  >
    {{ $t('printing.viewParcel') }} {{ selectedParcel.id }}
  </p>

  <PrintingSelectParcelModal />
</template>

<script lang="ts" setup>
const { selectedParcel, selectParcelModal } = storeToRefs(usePrintingStore())
</script>
