import type {
  Address,
  AppUser,
  Parcel,
  PrintingError,
  ShipmentTypeEnum
} from '~/models'

export const usePrintingStore = defineStore('printing', () => {
  const addresses = ref<Address[]>([])
  const errors = ref<PrintingError[]>([])
  const loadingPrintingLabel = ref(false)
  const loadingZohoValidation = ref(false)
  const loadingShipment = ref(true)
  const multipleDisputedParcels = ref(false)
  const parcels = ref<Parcel[]>([])
  const preferredShipmentMethods = ref<ShipmentTypeEnum[]>([])
  const searchQuery = ref<string>('')
  const selectedParcel = ref<Parcel>()
  const selectedPrinter = ref<string>('')
  const selectedShipmentMethod = ref<ShipmentTypeEnum | string>()
  const selectParcelModal = ref(false)
  const userDetails = ref<AppUser>()
  const labelChangeModalOpen = ref(false)
  const showPreviouslyPrintedAlert = ref(false)
  const showPreviouslyPrintedMultipleTimesAlert = ref(false)
  const showPrintCard = ref(true)
  const isActivePickupPoint = ref(true)

  function $resetPrintingStore() {
    loadingShipment.value = false
    multipleDisputedParcels.value = false
    errors.value = []
    userDetails.value = undefined
    parcels.value = []
    selectedParcel.value = undefined
    selectedShipmentMethod.value = ''
    showPrintCard.value = true
    showPreviouslyPrintedAlert.value = false
    showPreviouslyPrintedMultipleTimesAlert.value = false
    isActivePickupPoint.value = true
  }

  return {
    $resetPrintingStore,
    addresses,
    errors,
    isActivePickupPoint,
    labelChangeModalOpen,
    loadingPrintingLabel,
    loadingShipment,
    loadingZohoValidation,
    multipleDisputedParcels,
    parcels,
    preferredShipmentMethods,
    searchQuery,
    selectedParcel,
    selectedPrinter,
    selectedShipmentMethod,
    selectParcelModal,
    showPreviouslyPrintedAlert,
    showPreviouslyPrintedMultipleTimesAlert,
    showPrintCard,
    userDetails
  }
})
