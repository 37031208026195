import * as JSPM from 'jsprintmanager'
import { jsPDF } from 'jspdf'
import type { PrintData, RunnerLabelData } from '~/models'

export default function usePrintManager() {
  const { ampShipmentTypes, runnerShipmentTypes } = useShipmentData()
  const { selectedPrinter, userDetails, loadingPrintingLabel } =
    storeToRefs(usePrintingStore())
  const { removeError, pushError } = usePrintingErrors()
  const { getLocalOrGatewayUrl } = useApi()

  const loadingPrinters = ref(false)
  const clientPrinters = ref([{ value: '', text: 'Printers aan het ophalen' }])

  function jspmWSStatus() {
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Open) {
      return true
    }

    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Closed) {
      console.warn(
        'JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm'
      )
    }
    if (JSPM.JSPrintManager.websocket_status === JSPM.WSStatus.Blocked) {
      console.warn('JSPM has blocked this website!')
    }
    return false
  }

  function initPrintManager() {
    JSPM.JSPrintManager.license_url = getLocalOrGatewayUrl(
      'jspm/validate-licence'
    )

    // WebSocket settings
    JSPM.JSPrintManager.start()

    if (!JSPM.JSPrintManager.WS) return

    JSPM.JSPrintManager.WS.onStatusChanged = () => {
      if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
        loadingPrinters.value = true
        // get client installed printers
        JSPM.JSPrintManager.getPrintersInfo(
          JSPM.PrintersInfoLevel.Basic,
          '',
          JSPM.PrinterIcon.None
        ).then((printersList: any) => {
          clientPrinters.value = printersList.map((printer: any) => {
            return {
              value: printer.name,
              text: printer.name
            }
          })
          updatePrinterListDetails(clientPrinters.value[0])
        })
      } else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
        console.log(
          'JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm'
        )

        JSPM.JSPrintManager.stop()
        if (JSPM.JSPrintManager.WS) {
          JSPM.JSPrintManager.WS.stop()
        }
      }
    }
  }

  function updatePrinterListDetails(printer: { value: string; text: string }) {
    loadingPrinters.value = true
    selectedPrinter.value = printer.text
  }

  // On select printer remove error, if it existed bedfore
  watch(selectedPrinter, (newPrinter) => {
    if (newPrinter) removeError('noPrinterSelected')
  })

  function generateRunnerLabel(runnerLabelData: RunnerLabelData) {
    const doc = new jsPDF({
      unit: 'cm',
      format: [10.2, 15.2],
      orientation: 'landscape'
    })
    doc.setFontSize(18)

    doc.text(`${runnerLabelData.firstName} ${runnerLabelData.lastName}`, 1, 1)
    doc.text(
      `${runnerLabelData.street} ${runnerLabelData.streetNumber} ${
        runnerLabelData.streetNumberAddition ?? ''
      }`,
      1,
      2
    )
    doc.text(`${runnerLabelData.postalCode} ${runnerLabelData.city}`, 1, 3)
    return doc.output('blob')
  }

  function printLabel({ label, shipmentType, isBlob = false }: PrintData) {
    if (jspmWSStatus() && !!selectedPrinter.value) {
      const clientPrintJob = new JSPM.ClientPrintJob()
      const myPrinter = new JSPM.InstalledPrinter(selectedPrinter.value)
      clientPrintJob.clientPrinter = myPrinter

      const type = isBlob
        ? JSPM.FileSourceType.BLOB
        : JSPM.FileSourceType.Base64

      const file = new JSPM.PrintFilePDF(label, type, 'ShipmentLabel.pdf')
      file.pageSizing = JSPM.Sizing.Fit

      if (runnerShipmentTypes.includes(shipmentType)) {
        file.pageSizing = JSPM.Sizing.None
        file.printRotation = JSPM.PrintRotation.Rot90
      }

      if (ampShipmentTypes.includes(shipmentType)) {
        file.printRotation = JSPM.PrintRotation.Rot90
      }

      clientPrintJob.files.push(file)
      clientPrintJob.sendToClient().then((printRes: any) => {
        if (printRes.result.includes('Parsing OK')) {
          loadingPrintingLabel.value = false
        }
      })

      // eslint-disable-next-line
      // @ts-ignore
      clientPrintJob.onError((e: string) => pushError('printerError', e))
    }
  }

  return {
    clientPrinters,
    generateRunnerLabel,
    initPrintManager,
    loadingPrinters,
    printLabel,
    selectedPrinter,
    userDetails
  }
}
