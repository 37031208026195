import {
  arrayUnion,
  deleteField,
  type DocumentData,
  getDocs,
  type PartialWithFieldValue,
  query,
  Timestamp,
  where
} from 'firebase/firestore'
import type {
  Package,
  Parcel,
  PrintingParams,
  ProfileDataMap,
  ShipmentTypeEnum
} from '~/models'

export default function usePrinting() {
  const { t } = useI18n()
  const { getHouseNumber, parcelValidationCheck, shipmentAddress } =
    usePrintingValidation()
  const { user } = storeToRefs(useUserStore())
  const {
    createExternalReference,
    focusAndSelectInput,
    toast,
    truncateString
  } = useHelpers()
  const { reportError } = useSentry()
  const {
    errors,
    loadingPrintingLabel,
    loadingShipment,
    parcels,
    selectedParcel,
    selectedPrinter,
    selectedShipmentMethod,
    showPreviouslyPrintedAlert,
    showPreviouslyPrintedMultipleTimesAlert,
    showPrintCard,
    userDetails
  } = storeToRefs(usePrintingStore())
  const { collection, getDocument } = useFirebase()
  const { updateParcel } = useParcels()
  const { parcelPrintableStatuses } = useParcelsData()
  const { pushError } = usePrintingErrors()
  const { ShipmentTypes } = useShipment()
  const { ampShipmentTypes, runnerShipmentTypes } = useShipmentData()
  const { printLabel, generateRunnerLabel } = usePrintManager()
  const { apiPost } = useApi()

  type PrintAction = 'print' | 'reprint' | 'cancelAndCreateNewLabel' | 'error'
  ref<Partial<Parcel>>()
  const cancelParcelLabelState: Partial<Parcel> = {
    CurrentStatus: 'readyForSend',
    CurrentStatusLabel: t('parcel.readyForSend'),
    ShipmentId: '',
    ShipmentMethod: '',
    ShipmentProvider: '',
    ShipmentReference: '',
    TAndTCode: '',
    TAndTLink: ''
  }

  async function fetchParcelsBySearchFieldAndQuery(
    queryString: string,
    searchField: string
  ) {
    const parcels = query(
      collection('Parcels'),
      where(searchField, 'array-contains-any', [queryString]),
      where('CurrentStatus', 'in', parcelPrintableStatuses)
    )

    const { docs } = await getDocs(parcels)
    const parcelDocs = docs.map((doc: DocumentData) => ({
      ...(doc.data() as Parcel),
      id: doc.id,
      ref: doc.ref
    }))

    await handleParcelDocs(parcelDocs)
  }

  async function handleParcelDocs(parcelDocs: Parcel[]) {
    parcels.value = []

    if (parcelDocs.length === 0) {
      pushError('noParcel', t('printing.noParcelFoundBarcode'))
      return
    }

    if (parcelDocs.length === 1) {
      parcels.value = [{ ...parcelDocs[0] }]

      selectedParcel.value = parcels.value[0]
    }

    if (parcelDocs.length > 1) {
      parcels.value = [...parcelDocs]
      pushError('multipleParcels', t('printing.multipleParcelsFoundBarcode'))
    }
    await parcelValidationCheck()
  }

  async function handleShipmentTypeClicked(
    selectedShipmentType: ShipmentTypeEnum
  ) {
    loadingPrintingLabel.value = true
    const printAction = handlePrintAction(selectedShipmentType)

    if (printAction === 'error') return

    if (ampShipmentTypes.includes(selectedShipmentType)) {
      await handleAmpShipment(printAction, selectedShipmentType)
    } else if (runnerShipmentTypes.includes(selectedShipmentType)) {
      await handleRunnerShipment(printAction, selectedShipmentType)
    } else {
      await handleSendcloudShipment(printAction, selectedShipmentType)
    }

    loadingPrintingLabel.value = false
  }

  async function handleAmpShipment(
    printAction: PrintAction | undefined,
    selectedShipmentType: ShipmentTypeEnum
  ) {
    if (printAction === 'reprint') {
      await reprintAMPLabel(selectedShipmentType)
      postPrintActions(selectedShipmentType)

      return
    }

    if (printAction === 'cancelAndCreateNewLabel') await cancelLabel()

    await createAMPLabel(selectedShipmentType)
    postPrintActions(selectedShipmentType)
  }

  async function handleRunnerShipment(
    printAction: PrintAction | undefined,
    selectedShipmentType: ShipmentTypeEnum
  ) {
    if (printAction === 'reprint') {
      await reprintRunnerLabel(selectedShipmentType)
      postPrintActions(selectedShipmentType)

      return
    }

    if (printAction === 'cancelAndCreateNewLabel') await cancelLabel()

    // No need to cancel Runner labels as they don't have a portal
    await createRunnerLabel(selectedShipmentType)
    postPrintActions(selectedShipmentType)
  }

  async function handleSendcloudShipment(
    printAction: PrintAction | undefined,
    selectedShipmentType: ShipmentTypeEnum
  ) {
    if (printAction === 'reprint') {
      await reprintSendcloudLabel(selectedShipmentType)
      postPrintActions(selectedShipmentType)

      return
    }

    if (printAction === 'cancelAndCreateNewLabel') await cancelLabel()

    await createSendcloudLabel(selectedShipmentType)
    postPrintActions(selectedShipmentType)
  }

  function handlePrintAction(
    shipmentType: ShipmentTypeEnum
  ): PrintAction | undefined {
    if (!selectedParcel.value) return

    if (
      selectedParcel.value.ShipmentId &&
      selectedParcel.value.ShipmentMethod === shipmentType
    ) {
      return 'reprint'
    }

    if (selectedParcel.value.ShipmentMethod) {
      focusAndSelectInput('searchBundleId')
      return 'cancelAndCreateNewLabel'
    }

    if (loadingShipment.value || errors.value.length) return

    if (!selectedPrinter.value) {
      pushError('noPrinterSelected', t('printing.selectPrinter'))
      return 'error'
    }

    focusAndSelectInput('searchBundleId')

    return 'print'
  }

  async function postPrintActions(shipmentType: ShipmentTypeEnum) {
    if (!selectedParcel.value) return

    if (selectedParcel.value.ShipmentReference) {
      showPreviouslyPrintedMultipleTimesAlert.value = true
    } else {
      showPreviouslyPrintedAlert.value = true
    }

    showPrintCard.value = false
    selectedParcel.value.ShipmentMethod = shipmentType
    selectedShipmentMethod.value = shipmentType
  }

  async function getParcelShipmentId(parcelId: string) {
    const parcelSnapshot = await getDocument<Parcel>(`Parcels/${parcelId}`)
    if (parcelSnapshot) return parcelSnapshot.ShipmentId
    return null
  }

  async function reprintAMPLabel(shipmentType: ShipmentTypeEnum) {
    if (!selectedParcel.value) return

    const shipmentId = await getParcelShipmentId(selectedParcel.value.id)

    if (!shipmentId) return
    if (selectedParcel.value) selectedParcel.value.ShipmentId = shipmentId

    try {
      const data = await apiPost<{ base64Label: string | Blob }[]>(
        'amp/reprint',
        {
          headers: {
            'Content-Type': 'application/json'
          },
          body: {
            shipmentId
          }
        }
      )

      printLabel({
        label: data[0].base64Label,
        shipmentType
      })
    } catch (error) {
      reportError('Error reprinting label', {
        error,
        parcelId: selectedParcel.value.id
      })
      pushError('errorReprintingLabel', t('printing.errorReprintingLabel'))
      loadingPrintingLabel.value = false
    }
  }

  async function reprintSendcloudLabel(shipmentType: ShipmentTypeEnum) {
    if (!selectedParcel.value) return

    const shipmentId = await getParcelShipmentId(selectedParcel.value.id)
    if (!shipmentId) return
    if (selectedParcel.value) selectedParcel.value.ShipmentId = shipmentId

    try {
      const {
        Data: { label }
      } = await apiPost<{ Data: { label: string } }>(
        'shipping/{provider}/reprint/{shipmentId}',
        {},
        {
          provider: 'sendcloud',
          shipmentId
        }
      )

      printLabel({
        label,
        shipmentType
      })
    } catch (error) {
      reportError('Error reprinting label', {
        error,
        parcelId: selectedParcel.value.id
      })
      pushError('errorReprintingLabel', t('printing.errorReprintingLabel'))
      loadingPrintingLabel.value = false
    }
  }

  async function reprintRunnerLabel(shipmentType: ShipmentTypeEnum) {
    const runnerLabelData = getRunnerLabelData()
    if (!runnerLabelData) return

    const runnerLabel = generateRunnerLabel(runnerLabelData)
    printLabel({
      label: runnerLabel,
      shipmentType,
      isBlob: true
    })
  }

  async function cancelLabel(stopLoading: boolean = false) {
    if (!selectedParcel.value) return
    const shipmentId = selectedParcel.value?.ShipmentId
    const shipmentMethod = selectedParcel.value?.ShipmentMethod

    if (!shipmentId && !runnerShipmentTypes.includes(shipmentMethod ?? '')) {
      toast.error(t('printing.labelIsCancelledError'))
      loadingPrintingLabel.value = false
      throw new Error(
        `Error cancelling label: missing ShipmentMethod or Id. Method: ${shipmentMethod}, shipmentId: ${shipmentId}, parcelId: ${selectedParcel.value.id}`
      )
    }

    if (!shipmentMethod || runnerShipmentTypes.includes(shipmentMethod)) return

    try {
      const newShipmentId = await getParcelShipmentId(selectedParcel.value.id)
      if (newShipmentId === null || shipmentId === undefined) {
        return
      }

      selectedParcel.value.ShipmentId = newShipmentId

      if (ampShipmentTypes.includes(shipmentMethod)) {
        await apiPost('amp/cancel', {
          headers: {
            'Content-Type': 'application/json'
          },
          body: {
            shipmentId: newShipmentId
          }
        })
      } else {
        await apiPost(
          'shipping/{provider}/cancel/{shipmentId}',
          {},
          { provider: 'sendcloud', shipmentId }
        )
      }
    } catch (error) {
      reportError('Error cancelling label', {
        error,
        shipmentId: selectedParcel.value.ShipmentId
      })
      pushError('errorCancellingLabel', t('printing.errorCancellingLabel'))
    } finally {
      selectedParcel.value = {
        ...selectedParcel.value,
        ...cancelParcelLabelState
      }

      selectedShipmentMethod.value = undefined

      if (stopLoading) {
        loadingPrintingLabel.value = false
      }
    }
  }

  async function createSendcloudLabel(shipmentType: ShipmentTypeEnum) {
    const userProfileDataMap = userDetails?.value?.ProfileDataMap

    if (
      !userProfileDataMap ||
      !shipmentAddress.value ||
      !selectedParcel.value
    ) {
      return
    }

    const externalReference = await getExternalReference()

    let params: PrintingParams = {
      parcelPath: selectedParcel.value.ref.path.toString(), // Full document path
      parcel: {
        name: getFullName(userProfileDataMap),
        address: shipmentAddress.value.Street,
        city:
          shipmentType === 'trunkrsMailbox'
            ? `${shipmentAddress.value.City?.substring(0, 8)} | BRIEVENBUS`
            : shipmentAddress.value.City,
        house_number: getHouseNumber(),
        postal_code: shipmentAddress.value.PostalCode,
        email: userDetails?.value?.Email,
        telephone: userDetails?.value?.PhoneNumber,
        country: 'NL',
        request_label: true,
        quantity: 1,
        external_reference: externalReference,
        shipment: {
          id:
            shipmentType === 'trunkrsMailbox'
              ? parseInt(ShipmentTypes[shipmentType].id.substr(0, 4))
              : ShipmentTypes[shipmentType].id
        }
      },
      parcelId: selectedParcel.value.id
    }

    if (['postNLPickup', 'budbeeBox'].includes(shipmentType)) {
      params = {
        ...params,
        parcel: {
          ...params.parcel,
          to_service_point: userDetails.value?.PickupPoint?.Id
        }
      }
    }

    const {
      Data: { label, shipmentId }
    } = await apiPost<{ Data: { label: string; shipmentId: string } }>(
      'shipping/{provider}/create',
      {
        headers: {
          'Content-Type': 'application/json'
        },
        body: params
      },
      { provider: 'sendcloud' }
    )
    if (!selectedParcel.value) return

    printLabel({
      label: label,
      shipmentType
    })

    try {
      await updateParcel(selectedParcel.value.id, {
        CurrentStatus: 'labeled',
        CurrentStatusLabel: t('parcel.labeled'),
        ShipmentMethod: shipmentType,
        ShipmentProvider: getShipmentProvider(shipmentType),
        ShipmentId: shipmentId,
        StatusHistory: arrayUnion({
          Actor: `${user.value.displayName}`,
          Created: Timestamp.fromDate(new Date()),
          Status: 'labeled'
        }),
        DeliveryAddress: shipmentAddress.value,
        StorageLocation: deleteField()
      })

      selectedParcel.value.ShipmentId = shipmentId
      selectedParcel.value.ShipmentMethod = shipmentType
    } catch (error) {
      reportError('Sendcloud error', {
        error,
        parcelId: selectedParcel.value.id
      })
      pushError('sendCloudCreateLabel', t('printing.sendCloudError'))
      loadingPrintingLabel.value = false
    }
  }

  function getFullName({ FirstName, LastName }: ProfileDataMap): string {
    const firstName = FirstName ?? ''
    const lastName = LastName ?? ''

    let fullName = `${firstName} ${lastName}`

    const maxLengthFullName = 35

    if (fullName.length <= maxLengthFullName) {
      return fullName
    }

    const firstNames = firstName.split(' ')
    const initials = firstNames.map((name) => name[0] + '.').join('')

    fullName = `${initials} ${lastName}`

    return truncateString(fullName, maxLengthFullName)
  }

  async function createAMPLabel(shipmentType: ShipmentTypeEnum) {
    const packages = <Package[]>[{ minTemp: 2, maxTemp: 8 }]

    // Regular parcel
    if (shipmentType === 'ampCooledUncooled') {
      packages.push({
        minTemp: null,
        maxTemp: null
      })
    }

    const userProfileDataMap = userDetails?.value?.ProfileDataMap

    if (
      !selectedParcel.value ||
      !userProfileDataMap ||
      !shipmentAddress.value
    ) {
      return
    }

    // Create external reference before sending it to the API.
    // Saving this after creating the package causes the webhook to error on unkown reference.
    const externalReference = await getExternalReference()

    const [response] = await apiPost<
      {
        base64Label: string
        data: { orderId: string }
      }[]
    >('amp/create', {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        APROnummer: userDetails.value?.ExternalUserData?.AproData?.PatientId,
        deliveryCity: shipmentAddress.value.City,
        deliveryHouseNbr: getHouseNumber(),
        deliveryReference: externalReference,
        deliveryStreet: shipmentAddress.value.Street,
        deliveryZipCode: shipmentAddress.value.PostalCode,
        email: userDetails.value?.Email,
        fullName: `${userProfileDataMap.FirstName} ${userProfileDataMap.LastName}`,
        mobileNumber: userDetails.value?.PhoneNumber,
        operatorName: user.value.displayName,
        packages,
        parcelPath: selectedParcel.value.ref.path.toString() // Full document path
      }
    })

    if (!selectedParcel.value) return

    printLabel({
      label: response.base64Label,
      shipmentType
    })

    try {
      await updateParcel(selectedParcel.value.id, {
        CurrentStatus: 'labeled',
        CurrentStatusLabel: t('parcel.labeled'),
        ShipmentMethod: shipmentType,
        ShipmentProvider: getShipmentProvider(shipmentType),
        DeliveryAddress: shipmentAddress.value,
        ShipmentId: response.data.orderId,
        StatusHistory: arrayUnion({
          Actor: `${user.value.displayName}`,
          Created: Timestamp.fromDate(new Date()),
          Status: 'labeled'
        }),
        StorageLocation: deleteField()
      })

      selectedParcel.value.ShipmentId = response.data.orderId
      selectedParcel.value.ShipmentMethod = shipmentType
    } catch (error: any) {
      pushError('ampCreateLabel', JSON.stringify(error.message))
      loadingPrintingLabel.value = false
    }
  }

  async function createRunnerLabel(shipmentType: ShipmentTypeEnum) {
    if (!selectedParcel.value || !shipmentAddress.value) return

    const parcelUpdateData: PartialWithFieldValue<Parcel> = {
      ShipmentId: '',
      ShipmentMethod: shipmentType,
      ShipmentProvider: getShipmentProvider(shipmentType),
      ShipmentReference: await getExternalReference(),
      CurrentStatus: 'expressDelivery',
      DeliveryAddress: shipmentAddress.value,
      StatusHistory: arrayUnion({
        Actor: `${user.value.displayName}`,
        Created: Timestamp.fromDate(new Date()),
        Status: 'expressDelivery'
      }),
      StorageLocation: deleteField()
    }

    // Remove unneeded fields
    delete parcelUpdateData?.id
    delete parcelUpdateData?.ref

    const runnerLabelData = getRunnerLabelData()

    await updateParcel(selectedParcel.value.id, parcelUpdateData)

    if (!runnerLabelData || !selectedParcel.value) return

    const runnerLabel = generateRunnerLabel(runnerLabelData)

    selectedParcel.value.ShipmentMethod = shipmentType
    selectedParcel.value.ShipmentId = ''

    printLabel({
      label: runnerLabel,
      shipmentType,
      isBlob: true
    })
  }

  function getShipmentProvider(shipmentType: ShipmentTypeEnum) {
    switch (shipmentType) {
      case 'spoedkoerierDefault':
        return 'Spoedkoerier'
      case 'spoedkoerierColleague':
        return 'Collega'
      case 'trunkrsDefault':
      case 'trunkrsMailbox':
        return 'Trunkrs'
      case 'budbeeBox':
        return 'Budbee'
      case 'ampCooled':
      case 'ampCooledUncooled':
        return 'AMP'
      default:
        return 'PostNL'
    }
  }

  async function getExternalReference(): Promise<string> {
    const externalReference = createExternalReference()
    if (!selectedParcel.value) {
      // This should never happen
      //    just return a reference and let the process fail in a more logical place
      return externalReference
    }

    if (selectedParcel.value.ShipmentReference) {
      return selectedParcel.value.ShipmentReference
    }

    selectedParcel.value.ShipmentReference = externalReference
    await updateParcel(selectedParcel.value.id, {
      ShipmentReference: externalReference
    })

    return externalReference
  }

  function getRunnerLabelData() {
    const userProfileDataMap = userDetails?.value?.ProfileDataMap
    if (!selectedParcel.value || !shipmentAddress.value) return

    return {
      firstName: userProfileDataMap?.FirstName ?? '',
      lastName: userProfileDataMap?.LastName ?? '',
      street: shipmentAddress.value.Street ?? '',
      streetNumber: shipmentAddress.value.StreetNumber ?? '',
      streetNumberAddition: shipmentAddress.value.StreetNumberAddition ?? '',
      postalCode: shipmentAddress.value.PostalCode ?? '',
      city: shipmentAddress.value.City ?? ''
    }
  }

  return {
    cancelLabel,
    cancelParcelLabelState,
    fetchParcelsBySearchFieldAndQuery,
    getShipmentProvider,
    handleParcelDocs,
    handleShipmentTypeClicked
  }
}
